export interface SearchSuggestion {
  defaultImgProp: string
  height: string
  hint: string | null
  image: string
  text: string
}

type GetSuggestions = Record<number, SearchSuggestion>

async function getSuggestions(
  searchTerms: string,
  suggestUrl: string | null
): Promise<GetSuggestions | null> {
  try {
    if (!suggestUrl) {
      suggestUrl =
        'https://us.search.yahoo.com/sugg/gossip/gossip-us-partner?output=fxjson&appid=reb&command=' +
        searchTerms
    }
    const responseFromAPI = (await fetch(
      '/api/search' +
        '/' +
        searchTerms +
        '?' +
        new URLSearchParams({ suggestionUrl: encodeURI(suggestUrl.replace('{searchTerms}', searchTerms)) }),
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }
    )) as any

    if (!responseFromAPI.ok) {
      throw new Error(`Error! status: ${responseFromAPI.status}`)
    }

    let searchType = 'simple-search'
    if (suggestUrl.includes('gossip-us-fastbreak')) {
      searchType = 'rich-search'
    }
    const response = (await responseFromAPI.json()) as any

    const ss = {} as any
    let i
    if (response && searchType === 'rich-search') {
      for (i = 0; i < 10 && i < response[1].length; i++) {
        ss[i] = {}
        ss[i].image =
          response[4]['google:suggestdetail'][i].i == null
            ? "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='%235F6368'%3e%3cpath d='M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z' /%3e%3c/svg%3e"
            : response[4]['google:suggestdetail'][i].i
        ss[i].text = response[1][i]
        ss[i].hint =
          response[4]['google:suggestdetail'][i].a == null ? null : response[4]['google:suggestdetail'][i].a
        ss[i].height = response[4]['google:suggestdetail'][i].a == null ? '44px' : '65px'
        ss[i].defaultImgProp =
          response[4]['google:suggestdetail'][i].i == null
            ? 'searchbox-default-icon'
            : 'non-searchbox-default-icon'
      }
    } else if (response && searchType === 'simple-search') {
      for (i = 0; i < 10 && i < response[1].length; i++) {
        ss[i] = {}
        ss[i].image =
          "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='%235F6368'%3e%3cpath d='M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z' /%3e%3c/svg%3e"
        ss[i].text = response[1][i]
        ss[i].hint = null
        ss[i].height = '44px'
        ss[i].defaultImgProp = 'searchbox-default-icon'
      }
    }
    return ss
  } catch (error) {
    return null
  }
}

export { getSuggestions }
